<template>
    <el-drawer
        title="添加"
        :visible.sync="drawer"
        :modal="true"
        :wrapperClosable="false"
        size="600px"
        @close="closeDrawer"
        :modal-append-to-body="false"
        :direction="direction">

        <!-- title -->
        <div class="drawer_form">
            
            <el-form :model="form" status-icon :rules="rules" ref="form" label-width="120px" class="demo-ruleForm">
                
                <div class="form-body">
                    <overlay-scrollbars style="width: 100%;height: 100%;" class="el-fade-in">
                        <el-form-item label="菜单名称" prop="title">
                            <el-input type="text" v-model="form.title" autocomplete="off"></el-input>
                        </el-form-item>
                        <!-- <el-form-item label="图标" prop="">
                            <el-upload
                                class="upload-demo"
                                :action="url"
                                :headers="{Authorization:$store.state.user.token}"
                                accept=" .png , .jpg, .jepg"
                                :on-success="uploadSuccess"
                                :on-error="uploadError"
                                :on-exceed="uploadExceed"
                                :limit="1"
                                list-type="picture">
                                <el-button size="small" type="primary">点击上传</el-button>
                            </el-upload>
                        </el-form-item> -->
                        <el-form-item label="是否外链" prop="">
                            <el-select v-model="form.isFrame" placeholder="请选择" class="w-full">
                                <el-option
                                v-for="item in $store.state.dict.bool"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="排序" prop="">
                            <el-input type="text" v-model="form.menuSort" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="前端路由" prop="">
                            <el-input type="text" v-model="form.path" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="权限" prop="">
                            <el-input type="text" v-model="form.permission" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="上级ID" prop="">
                            <el-input type="text" v-model="form.pid" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="类型" prop="">
                            <span>{{ $store.state.dict.type.find(res=>res.value==form.type)['label'] }}</span>
                        </el-form-item>
                    </overlay-scrollbars>
                </div>

                <div class="form-foot d-flex a-center j-center">
                    <el-button @click="onSubmit" type="primary" class="" style="width:200px">添加</el-button>
                </div>
            </el-form>
        </div>

    </el-drawer>
    
</template>

<script>
import {getFileUrl} from '@/request/api/login'
import {create} from '@/request/api/menus'
export default {
    data(){
        return {
            drawer: true, //表单
            direction: 'rtl',//抽屉方向
            loading:false,
            url:'',
            form:{
                title:'',
                icon: "",
                id: 0,
                isFrame: false,
                menuSort: 0,
                path: "",
                permission: "",
                pid: null,
                type:1
            },
            
            rules:{
                title:[
                    { required: true, message: '请输入名称', trigger: 'blur' },
                ],
            }
        }
    },
    created(){
        var type =this.$route.query.type
        this.form.type = type?parseInt(type)+1:1
        this.form.pid = parseInt(this.$route.query.id) || null
        getFileUrl().then(res=>{
            this.url = res
        })
    },
    methods:{
        onSubmit(){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.req()
                } else {
                    return false;
                }
            });
        },
        
        //req
        async req(){
            let {code,msg} = await create(this.form)
            if(code!=0) return this.$message.error(msg)
            this.$router.go(-1)
            this.$message.success(msg)
            
        },

        //上传成功
        uploadSuccess(res, file, fileList){
            this.form.icon = res.data.url
            this.$message.success(res.msg)           
        },
        //上传失败
        uploadError(file,filelist){
            this.$message.error('图标上传失败')
        },
        
        //超出
        uploadExceed(file,filelist){
            this.$message.error("图标上传超出："+filelist.length)
        },


        //重置表单
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        //关闭抽屉
        closeDrawer(){
            this.$router.go(-1)
        }
    }

}
</script>


<style lang="less" scoped>
.drawer_form {
    width: 96%;
    margin: auto;
    .drawer_form_title {
        color: #17233d;
        font-size: 22px;
        font-weight: 400;
        text-align: center;
        line-height: 48px;
        margin-bottom: 20px;
    }

    .drawer_line {
        margin-bottom: 30px;
        .drawer_line_text {
            width: 120px;
            margin-right: 20px;
            text-align: right;
            color: #515a6e;
        }    
    }

}
.form-items {
    background-color:#f5f6fb;padding:15px 10px;height:500px;
}

/deep/ .form-items .el-form-item ,/deep/ .form-items .el-form-item .el-form-item__content{
    width: 100%;
}

</style>

